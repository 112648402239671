import React, {useEffect, useState} from "react";
import Button from "../component/button"
import TextInput from "../component/textInput"
import Item from "../component/item";

const loginDiv = {
  margin: "10px auto auto auto",
  width: "30%",
  minWidth: "300px",
  border: "3px solid black",
  padding: "10px",
  borderRadius: "10px",
  textAlign: 'center'
}

export default function Home({connexion, data, offerButton}) {
  const [pseudo, setPseudo] = useState("");
  const [password, setPassword] = useState("");

  function onPseudoChange(pseudo){
    setPseudo(pseudo)
  }

  function onPasswordChange(password) {
    setPassword(password)
  }

  function checkAccount() {
    connexion(pseudo, password)
  }

  return (
    <div>
      {data.connected==="admin" ?
        <div>
          {
            data.items.length > 0 && data.items.map((element, i) => {
              return <Item data={element} userData={data.userId} offerButton={offerButton.bind()} hide={true}/>
            })
          }
        </div>
      : data.connected==="user" ?
        <div>
          {
            data.items.length > 0 && data.items.map((element, i) => {
              return <Item data={element} userData={data.userId} offerButton={offerButton.bind()}/>
            })
          }
        </div>
      :
      <div>
        <div style={loginDiv}>
          <TextInput placeholderText="pseudo" value={pseudo} onChange={onPseudoChange}/>
          <TextInput placeholderText="mot de passe" value={password} onChange={onPasswordChange} isPassword={true}/>
          <Button name="connexion" onClick={checkAccount}/>
          <p>Pas de compte ? Mot de passe oublié ? <br/> Envoyez un mail à <a href="mailto:contact@anthonyierace.be">contact@anthonyierace.be</a> avec votre pseudo</p>
        </div>
      </div>
      }
      
    </div>
  );
}
