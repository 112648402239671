import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; 

let db = false;

export const getDb = () => {
    if(!db){
      const firebaseConfig = {
        apiKey: "AIzaSyDxwPU4SX6OW5RJpYBh3UFHNfNMM2wtpwA",
        authDomain: "whishlist-6b277.firebaseapp.com",
        projectId: "whishlist-6b277",
        storageBucket: "whishlist-6b277.appspot.com",
        messagingSenderId: "1082564181900",
        appId: "1:1082564181900:web:3a4dffe27040b6d6b320a9"
      };

        const app = initializeApp(firebaseConfig)

        db = getFirestore(app)
    }

    return db
}