import React from 'react';

const divStyle = {
  margin: '20px 0px 0px 20px'
}

const inputStyle = {
  border: '1px solid black',
  borderRadius : '10px',
  padding: '0px 0px 0px 20px',
  height: '40px'
}

export default function TextInput({placeholderText, value, onChange, isPassword}) {

  function updateInputValue(evt) {
    const val = evt.target.value;
    onChange(val)
  }

  return (
    <div style={divStyle}>
      <div>
        <input 
            placeholder={placeholderText}
            value={value}
            onChange={evt => updateInputValue(evt)}
            style={inputStyle}
            type={isPassword? "password" : ""}/>
      </div>
    </div>
  );
}