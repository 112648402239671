// src/Modal.js
import React from 'react';
import '../css/modal.css';

function Modal({ closeModal }) {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <h1><u>Quelques précisions concernant le fonctionnement de ce site.</u></h1>
        <p>
          <strong>1.</strong> je me suis <strong>bloqué</strong> la possibilité de voir si un cadeau a été sélectionné.<br/>
          <strong>2.</strong> Vos mots de passes sont enregistré de manière <strong>chiffrée</strong>.<br/>
          <strong>3.</strong> Je m'engage à ne rien acheter dans cette liste entre le <strong>01/12</strong> et le <strong>01/03</strong><br/>
          <strong>4.</strong> Si vous changez d'avis, merci de <strong>modifier</strong> le status sur le site<br/>
          <strong>5.</strong> Cette liste n'est là qu'à <strong>titre indicatif</strong> et pour éviter les doublons. Si vous avez une autre idée, foncez! (j'aime être surpris :p)<br/>
          <strong>6.</strong> La liste est simplement triée par ordre d'ajout, il n'y a aucun rapport avec un ordre de préférence<br/>
          <strong>7.</strong> Si vous constatez un <strong>bug</strong> ou que vous avez une <strong>idée pour améliorer</strong> le site ou simplement <strong>une demande particulière</strong> n'hésitez pas à m'envoyer un mail à : <a href="mailto:contact@anthonyierace.be">contact@anthonyierace.be</a>
        </p>
        <button onClick={closeModal}>Fermer</button>
      </div>
    </div>
  );
}

export default Modal;
