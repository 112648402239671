import React from 'react';

  const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '105px',
  padding: '10px',
  margin: '10px auto 10px auto',
  border: '1px solid black',
  borderRadius: '10px',
  cursor: 'pointer'
}

export default function Button({state, name, onClick}) {

  return (
    <div style={buttonStyle} onClick={() => onClick()}>
      {name}
    </div>
  );
}